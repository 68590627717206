import { notDesktop, styled } from '@obvio/app'
import { motion } from 'framer-motion'

export const ImageWrap = styled.div<{ $ratio: number }>`
  height: 60vh;
  aspect-ratio: ${(_, { $ratio }) => $ratio ?? 1};
  @media ${notDesktop} {
    height: 60vw;
  }
`

export const Carousel = motion(styled.div`
  position: absolute;
  top: 0;
  left: 150vw;
  height: 100%;
  display: flex;
  z-index: 1;
`)

export const Wrap = motion(styled.div<{ $count: number }>`
  text-align: center;
  height: calc(100vw * ${(_, { $count }) => $count});
  position: relative;
`)

export const ElementWrap = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const TextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

export const Element = styled.div`
  height: 100%;
`

export const TextMotion = motion(styled.div`
  position: absolute;
  > h2 {
    font-size: 15vh !important;
  }
  @media ${notDesktop} {
    > h2 {
      font-size: 10vw !important;
    }
  }
`)

export const CarouselWrap = styled.div`
  height: 60vh;
  position: sticky;
  top: 25vh;
  overflow: hidden;
  @media ${notDesktop} {
    height: 60vw;
    overflow: hidden;
  }
`
