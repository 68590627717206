import { styled, Text, useTranslation } from '@obvio/app'
import { SvgArrowSmall } from '@obvio/svg'
import { Stack } from '@obvio/ui'
import { motion } from 'framer-motion'

import type { ReactElement } from 'react'

const CapitalizedText = styled(Text)`
  text-transform: uppercase;
`

const Wrap = motion(styled(Stack)`
  align-items: center;
`)

type ScrollDownProps = {
  className?: string
}

export function ScrollDown({ className }: ScrollDownProps): ReactElement {
  const { t } = useTranslation()

  return (
    <Wrap
      spacing="extraSmall"
      className={className}
      initial={{ opacity: 0, y: 25 }}
      whileInView={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 25 }}
      viewport={{ once: true }}
    >
      <CapitalizedText tag="span">{t('label.scrollDown')}</CapitalizedText>
      <motion.div
        animate={{ y: [2, 5, 2] }}
        transition={{ repeat: Infinity, duration: 1 }}
      >
        <SvgArrowSmall />
      </motion.div>
    </Wrap>
  )
}
