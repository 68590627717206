import {
  AssetsProvider,
  Image,
  useQuery,
  Text,
  Link,
  styled,
  useContextPath,
} from '@obvio/app'
import { useScroll, useSpring, useTransform } from 'framer-motion'
import { useRef, useMemo } from 'react'

import {
  ImageWrap,
  CarouselWrap,
  ElementWrap,
  TextWrap,
  TextMotion,
  Carousel,
  Wrap,
  Element,
} from './ArtistOfTheDay/components'
import { useCursorContext } from './Cursor'
import { ScrollDown } from './ScrollDown'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const PositionedScrollDown = styled(ScrollDown)`
  position: absolute;
  left: ${(theme) => theme.spacing.large};
  bottom: 1rem;
`

export const ARTIST_OF_THE_DAY_SELECT = {
  id: true,
  images: true,
  title: true,
  slug: true,
  merchant: {
    name: true,
  },
} as const

type ArtistOfTheDayProps = {
  id: string
}

type ImgProps = {
  images: ImageAsset[]
  id: string
}

function Img({ images, id }: ImgProps) {
  const img = useMemo(() => images[0], [images])
  return (
    <AssetsProvider path={`/product/${id}`}>
      <ImageWrap $ratio={img.ratio}>
        <Image img={img} />
      </ImageWrap>
    </AssetsProvider>
  )
}

export function ArtistOfTheDay({
  id,
}: ArtistOfTheDayProps): ReactElement | null {
  const productPath = useContextPath('ecommerce.product')
  const { setValue } = useCursorContext()
  const ref = useRef(null)
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: [-0.1, 0.9],
  })
  const { data } = useQuery('getProducts', {
    args: {
      byMerchant: [id],
      take: 3,
    },
    select: ARTIST_OF_THE_DAY_SELECT,
  })
  const scrollBy = useTransform(
    scrollYProgress,
    [0, 1],
    [0, 50 + (data?.length ?? 0) * 100],
  )

  const textMove = useTransform(scrollYProgress, [0, 0.2], [0, 75])
  const textMoveSpring = useSpring(textMove, {
    stiffness: 70,
    damping: 30,
    restDelta: 0.0001,
  })
  const textMoveSpringLeft = useTransform(
    textMoveSpring,
    (v) => `translateX(${v}vw)`,
  ) as unknown as string

  const textMoveSpringRight = useTransform(
    textMoveSpring,
    (v) => `translateX(-${v}vw)`,
  ) as unknown as string

  const scrollBySpring = useSpring(scrollBy, {
    stiffness: 100,
    damping: 30,
    restDelta: 0.001,
    bounce: 0,
    mass: 0.1,
  })
  const scrollBySpringVw = useTransform(
    scrollBySpring,
    (v) => `translateX(-${v}vw)`,
  ) as unknown as string

  if (!data) {
    return null
  }
  return (
    <Wrap $count={data.length} ref={ref}>
      <CarouselWrap>
        <Carousel style={{ transform: scrollBySpringVw }}>
          {data.map((product) => (
            <ElementWrap key={product.id}>
              <Element
                onMouseOver={() => setValue('EXPLORE')}
                onMouseOut={() => setValue(null)}
              >
                <Link href={`${productPath}/${product.slug}`}>
                  <Img images={product.images} id={product.id} />
                </Link>
                <TextWrap>
                  <Text>{product.title}</Text>
                  <Text>by {product.merchant?.name}</Text>
                </TextWrap>
              </Element>
            </ElementWrap>
          ))}
        </Carousel>
        <TextMotion
          style={{ transform: textMoveSpringLeft, left: '-50vw', top: '2vw' }}
        >
          <Text tag="h2">ARTIST OF</Text>
        </TextMotion>
        <TextMotion
          style={{
            transform: textMoveSpringRight,
            right: '-50vw',
            bottom: '2vw',
            zIndex: 2,
          }}
        >
          <Text tag="h2">THE DAY</Text>
        </TextMotion>
        <PositionedScrollDown />
      </CarouselWrap>
    </Wrap>
  )
}
